<template>
  <div>
    <div class="edit-icon-settings d-flex align-items-center">
      <feather-icon
        v-if="!isEditable"
        v-b-tooltip.hover.bottom
        icon="Edit2Icon"
        :title="$t('form-create-item.edit')"
        size="20"
        class="text-primary zindex-1"
        :class="!isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'"
        @click="openEdit"
      />
      <feather-icon
        v-if="isEditable"
        icon="XIcon"
        size="20"
        class="text-primary zindex-1 mr-1"
        :class="!isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'"
        @click="closeEdit"
      />
      <feather-icon
        v-if="isEditable"
        v-b-tooltip.hover.bottom
        icon="SaveIcon"
        :title="$t('header.action.save-layout')"
        size="20"
        class="text-primary zindex-1"
        :class="!isStaff && !canEdit ? 'd-block mr-n50' : 'd-none d-lg-block'"
        @click="$refs.baseGridContainer.handleEditOrder()"
      />
    </div>
    <b-card>
      <div class="d-flex justify-content-between pr-1 mb-2">
        <h4 class="card-title" style="text-transform: capitalize">
          {{ translatedType }}
        </h4>
        <div class="d-flex justify-content-end align-items-center">
          <v-select
            v-if="$route.params.typeKey === 'noKey'"
            v-model="typeSelected"
            class="select-control selection"
            :clearable="false"
            label="name"
            :searchable="false"
            :options="typesSelect"
            :change="updateBreadcrumbs() && checkEntity()"
          />
          <b-button
            v-if="isStaff || canAdd"
            v-b-modal.modal-create-people
            class="px-3 ml-2"
            variant="outline-primary"
          >
            {{ $t("projects.create") }}
          </b-button>
        </div>
      </div>
      <base-grid-container
        ref="baseGridContainer"
        #default="{ item }"
        :items="people"
        :searchType="typeSelected"
        :loading="isLoading"
        :itemType="true"
        :typeItem="true"
        :isDraggable="isEditable"
        :loading-next="isLoadingNextPage"
        :placeholder="placeholder"
        :placeholder-message="
          $t('available.message', { itemName: this.$t('people.placeholder') })
        "
        :has-more="hasMore"
        :error="errorMessage"
        @load-next="loadNextPage"
        @changeOrder="changeOrder"
      >
        <speaker-card :speaker="item" is-detail @load-items="handleRemove"/>
      </base-grid-container>
    </b-card>
    <!-- TO DO: Refactor Create People in one component -->
    <!-- Create People-->
    <b-modal
      id="modal-create-people"
      :title="
        $t('people.create-people', {
          type:
            $route.params.typeKey === 'noKey'
              ? $tc('people.title', 1)
              : noKey.name,
        })
      "
      :ok-title="$t('form.actions.save')"
      :cancel-title="$t('form-create-item.cancel')"
      cancel-variant="outline-secondary"
      centered
      size="lg"
      @ok="addPeople"
    >
      <b-form>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="name" class="font-weight-bold">
                {{ $t("people.name") }}*
              </label>
              <b-form-input
                id="name"
                v-model="person.name"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="surnmame" class="font-weight-bold">
                {{ $t("backoffice.owner.surname") }}
              </label>
              <b-form-input
                id="surname"
                v-model="person.surname"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="job" class="font-weight-bold">
                {{ $t("people.job-title") }}:</label
              >
              <b-form-input
                id="job"
                v-model="person.jobTitle[locale]"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="organization" class="font-weight-bold">
                {{ $t("people.organization") }}:</label
              >
              <b-form-input
                id="organization"
                v-model="person.organization"
                type="text"
                :placeholder="$t('form.type-placeholder')"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="12" md="6">
            <b-form-group>
              <label for="email" class="font-weight-bold">
                Email
              </label>
              <b-form-input id="email" v-model="person.email" type="text" />
            </b-form-group>
          </b-col>
          <b-col cols="12" md="6">
            <label for="type" class="font-weight-bold">
              {{ $t("people.type") }}*</label
            >
            <v-select
              v-model="noKey"
              name="classifier-filter"
              :clearable="false"
              :searchable="false"
              label="name"
              :options="options"
              class="mb-2"
            />
          </b-col>
          <b-col cols="12" md="6">
            <label for="published" class="font-weight-bold">
              {{ $t("people.published") }}
            </label>
            <b-form-checkbox id="published" v-model="person.published" switch />
          </b-col>
        </b-row>

        <b-form-group>
          <label class="mt-2" for="description"
            >{{ $t("form-create-item.description") }}:</label
          >
          <quill-editor v-model="person.description[locale]" />
        </b-form-group>
        <!-- Photo -->
        <label class="font-weight-bold mt-3"> {{ $t("people.photo") }}: </label>
        <b-media no-body vertical-align="center">
          <b-media-body>
            <b-form-file
              v-model="imageURL"
              class="pointer"
              :placeholder="$t('form-create-item.image-placeholder')"
              :browse-text="$t('form-create-item.image-button')"
            />
            <b-img
              ref="refPreviewEl"
              :src="avatarSrc || placeholder"
              class="d-block mx-auto"
              style="max-width: 150px"
            />
          </b-media-body>
        </b-media>
        <!-- Logo -->
        <label class="font-weight-bold mt-3">
          {{ $t("people.logo") }}
        </label>
        <b-media no-body vertical-align="center">
          <b-media-body>
            <b-form-file
              v-model="organizationLogoURL"
              class="pointer"
              :placeholder="$t('form-create-item.image-placeholder')"
              :browse-text="$t('form-create-item.image-button')"
            />
            <b-img
              ref="refPreviewEl"
              :src="logoSrc || logoPlaceholder"
              class="d-block mx-auto"
              style="max-width: 150px"
            />
          </b-media-body>
        </b-media>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BButton,
  BModal,
  BFormGroup,
  BFormInput,
  BForm,
  BMedia,
  BImg,
  BMediaAside,
  BMediaBody,
  BFormFile,
} from "bootstrap-vue";
import placeholder from "@/assets/images/placeholders/light/people.svg";
import { translateTranslationTable } from "@core/libs/i18n/utils";
import SpeakerCard from "@/views/apps/people/components/SpeakerCard.vue";
import BaseGridContainer from "@core/components/containers/base/BaseGridContainer.vue";
import Service from "@/config/service-identifiers";
import OrderBy from "@/@core/constants/OrderBy";
import LogoPlaceholder from "@/assets/images/placeholders/light/icono-general-light.png";
import ToastNotificationsMixin from "@core/mixins/toast-notifications/ToastNotificationsMixin";
import vSelect from "vue-select";
import { checkPermissions } from "@/@core/utils/roles-utils";
import { quillEditor } from "vue-quill-editor";

export default {
  name: "PeopleDetails",
  components: {
    BaseGridContainer,
    SpeakerCard,
    BCard,
    BButton,
    BModal,
    BFormGroup,
    BFormInput,
    BForm,
    BMedia,
    BImg,
    BMediaAside,
    BMediaBody,
    vSelect,
    BFormFile,
    quillEditor,
  },
  mixins: [ToastNotificationsMixin],
  props: {
    typeKey: {
      type: String,
      required: true,
    },
    perPage: {
      type: Number,
      default: 16,
    },
  },
  data() {
    return {
      people: [],
      typeName: null,
      isLoading: false,
      isLoadingNextPage: false,
      hasMore: false,
      errorMessage: null,
      isEditable: false,
      currentPage: 0,
      person: {
        name: "",
        typeKey: "",
        published: false,
        surname: "",
        jobTitle: {},
        organization: "",
        description: {},
      },
      noKey: null,
      imageURL: null,
      organizationLogoURL: null,
      logoSrc: "",
      avatarSrc: "",
      types: [],
      type: "",
      typesSelect: [{ name: this.$t("display-type-options.show-all") }],
      typeSelected: { name: this.$t("display-type-options.show-all") },
      options: [],
    };
  },
  computed: {
    firstItems() {
      return this.$route.params.typeKey === "nokey"
        ? this.$store.getters.entities.unpaginated
        : this.$store.getters.entitiesByType[this.$route.params.typeKey]
            ?.unpaginated || [];
    },
    translatedType() {
      const app = Object.values(this.$store.getters.apps.apps)?.find(
          (item) => item.key === "people"
        );
      if (this.typeKey === "noKey") {
        return (
          translateTranslationTable(
            this.$store.state.locale.currentLocale,
            app?.customizationName || app?.name
          ) || this.$tc("people.title", 2)
        );
      }

  if(app.customization?.[this.typeKey] != null){
    return (
        translateTranslationTable(
          this.$store.state.locale.currentLocale,
          app.customization?.[this.typeKey]?.customizationName || app.customization?.[this.typeKey]
        ) || Object.values(app.customization?.[this.typeKey]?.customizationName || app.customization?.[this.typeKey])
      );
  }

      return (
        translateTranslationTable(
          this.$store.state.locale.currentLocale,
          this.typeName
        ) || this.$tc("people.title", 2)
      );
    },
    placeholder() {
      return placeholder;
    },
    logoPlaceholder() {
      return LogoPlaceholder;
    },
    locale() {
      return this.$store.state.locale.currentLocale;
    },
    isStaff() {
      return this.$store.getters.currentCollective.isStaff;
    },
    loggedMemberRoles() {
      return this.$store.getters.loggedMemberRoles;
    },
    canAdd() {
      return checkPermissions(
        "create",
        "success-story",
        this.loggedMemberRoles,
        this.collective
      );
    },
    translatedTitle() {
      return translateTranslationTable(
        this.$store.state.locale.currentLocale,
        this.type.name
      );
    },
  },
  watch: {
    organizationLogoURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.logoSrc = value;
            })
            .catch(() => {
              this.logoSrc = null;
            });
        } else {
          this.logoSrc = null;
        }
      }
    },
    imageURL(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          this.base64Encode(newValue)
            .then((value) => {
              this.avatarSrc = value;
            })
            .catch(() => {
              this.avatarSrc = null;
            });
        } else {
          this.avatarSrc = null;
        }
      }
    },
  },
  async created() {
    await this.loadNextPage();
    await this.fetchTypes();
    this.person.typeKey = this.$route.params.typeKey;
  },
  methods: {
    openEdit() {
      this.isEditable = true;
    },
    closeEdit() {
      this.$store.commit("SET_ITEMS", {
        type: "people",
        ...(this.$route.params.typeKey === "nokey"
          ? ""
          : { customName: "people" }),
        ...(this.$route.params.typeKey === "nokey"
          ? ""
          : { storedKey: this.$route.params.typeKey }),
        forceAPICall: true,
        page: 1,
        data: {
          data: this.firstItems,
          meta: {
            current_page: 1,
            total: this.firstItems.length,
          },
        },
      });
      this.isEditable = false;
    },
    changeOrder(items) {
      this.isEditable = false;
    },
    base64Encode(data) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(data);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
      });
    },
    async fetchTypes() {
      if (this.$store.getters.types?.AllPeopleTypes == null) {
        await this.$store.dispatch("getItems", {
          itemType: "types",
          storedKey: "AllPeopleTypes",
          page: 1,
          perPage: 200,
          requestConfig: {
            modelType: "people",
            getCommunityTypes: 1,
            selectAll: 1,
          },
        });
      }

      if (this.$store.getters.types?.people == null) {
        await this.$store.dispatch("getItems", {
          itemType: "types",
          storedKey: "people",
          page: 1,
          perPage: 200,
          requestConfig: {
            getCommunityTypes: 1,
            modelType: "people",
          },
        });
      }

      this.$store.getters.types?.AllPeopleTypes?.unpaginated.map((item) =>
        this.typesSelect.push({
          name:
            typeof item.name === "object"
              ? item.name[this.locale] ||
                item.name.en ||
                item.name.es ||
                item.name.ca
              : item.name,
          key: item.key,
        })
      );
      this.options = this.typesSelect.filter(item=> item.name != this.$t("display-type-options.show-all"))
      this.$store.getters.types.people?.unpaginated.map((item) => {
        typeof item.name === "object"
          ? this.types.push({
              name:
                item.name[this.locale] ||
                item.name.en ||
                item.name.es ||
                item.name.ca,
              key: item.key,
            })
          : "";
      });
      this.person.typeKey = this.type.key;
      if (this.$route.params.typeKey != "noKey") {
        this.typeName = this.people.find(
          ({ type }) => type.key === this.typeKey
        ).type.name;
        this.noKey = this.types.filter((item) => {
          return (
            item.name ===
            (this.typeName[this.locale]
              ? this.typeName[this.locale]
              : Object.values(this.typeName)[0])
          );
        })[0];
      }
    },
    async loadNextPage() {
      this.isLoadingNextPage = true;
      try {
        const response = await this.$store.$service[Service.BackendClient].get(
          "people",
          {
            params: {
              communityKey: this.$store.getters.currentCollective.key,
              typeKey: this.typeKey !== "noKey" ? this.typeKey : undefined,
              count: this.perPage,
              page: this.currentPage + 1,
              orderBySurname: OrderBy.ASC,
            },
          }
        );
        this.currentPage += 1;
        this.people.push(...response.data.data);
        this.hasMore = this.people.length < response.data.meta.total;
        if (!this.typeName) {
          this.typeName = this.people.find(
            ({ type }) => type.key === this.typeKey
          ).type.namePlural;
          this.updateBreadcrumbs(this.typeName);
        }
      } catch (error) {
        this.errorMessage = this.$t("error-message.load-failed");
      }
      this.isLoadingNextPage = false;
    },
    async loadPage() {
      this.isLoadingNextPage = true;
      const response = await this.$store.$service[Service.BackendClient].get(
        "people",
        {
          params: {
            communityKey: this.$store.getters.currentCollective.key,
            typeKey: this.typeKey !== "noKey" ? this.typeKey : undefined,
            count: this.perPage,
            page: 1,
            orderBySurname: OrderBy.ASC,
          },
        }
      );

      this.people = response.data.data;

      this.isLoadingNextPage = false;
    },
    updateBreadcrumbs(translatedType) {
      let breadcrumbs;
      if (translatedType) {
        breadcrumbs = [
          {
            text: translatedType[this.locale]
              ? translatedType[this.locale]
              : Object.values(translatedType)[0],
            active: true,
          },
        ];
      } else {
        breadcrumbs = [
          {
            text: this.translatedType||this.$t("people.breadcrumb-text.people-details"),
            active: true,
          },
        ];
      }
      this.$store.commit("app/SET_BREADCRUMBS", breadcrumbs);
    },
    async addPeople() {
      if (this.person.name !== "" && this.person.surname !== "") {
        const images = {};
        if (this.organizationLogoURL != null) {
          images.organizationLogoURL = this.organizationLogoURL;
        }
        if (this.imageURL != null) {
          images.imageURL = this.imageURL;
        }
        const settings = {
          itemType: "people",
          storedKey: this.type?.key,
          requestConfig: {
            name: this.person.name,
            typeKey: this.noKey?.key,
            published: this.person.published ? 1 : 0,
            surname: this.person.surname,
            emails:[{email:this.person.email,label:"Email"}],
            jobTitle: this.person.jobTitle,
            organization: this.person.organization,
            description: this.person.description,
          },
        };

        try {
          await this.$store.dispatch("createItem", {
            item: settings,
            file: images,
          });
          this.notifySuccess(
            this.$t("people.messages.success", { type: this.translatedType })
          );
          this.loadPage();
          this.person = {
            name: "",
            typeKey: "",
            published: false,
            surname: "",
            jobTitle: {},
            organization: "",
            description: {},
          };
          this.newUpdate = true;
          this.imageURL = null;
          this.organizationLogoURL = null;
        } catch {
          this.notifyError(
            this.$t("people.messages.error", { type: this.translatedType })
          );
        }
      } else {
        this.notifyError(
          this.$t("people.messages.error", { type: this.translatedType })
        );
      }
    },
    handleRemove(key) {
      this.people = this.people.filter(item=>item.key != key)
    }
  },
};
</script>

<style lang="scss">
.edit-icon-settings {
  position: absolute;
  right: 42px;
  top: 108px;
  cursor: pointer;
}
.selection {
  width: 216px;
}
</style>
